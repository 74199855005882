import React from 'react';
import styled from 'styled-components';
import Container from '../components/container';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Headline from '../components/headline';

const NecesitasAyuda = () => (
  <Layout>
    <SEO
      title="¿Necesitas Ayuda?"
      description="Si tú o algún familiar entre 0 y 25 años padece alguna enfermedad crónico degenerativa y vives en el Estado de México, en AMVI queremos ayudarte."
    />
    <Container>
      <Headline>¿Necesitas ayuda?</Headline>
      <p>
        ¡Hola! Si tú o algún familiar entre 0 y 25 años padece alguna enfermedad
        crónico degenerativa y vives en el Estado de México, en AMVI queremos
        ayudarte.
      </p>
      <Iframe
        src="https://amvi-mx.typeform.com/to/gVy9ih"
        title="Necesitas Ayuda"
      />
    </Container>
  </Layout>
);

const Iframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 450px;
`;

export default NecesitasAyuda;
